
::-ms-input-placeholder {
	color: #808080 !important;
	opacity: 0.5 !important;
}

body {
	// font-family: "Inter-Medium";
	background-color: $theme-bg;
}
a,
a:hover {
	color: $font-color1;
	text-decoration: none;
}
ul {
	padding-left: 0px;
	margin: 0;
}
li {
	list-style: none;
}

h3,
p {
	// font-family: "Inter-Regular";
}

.product-name {
//   font-family: 'proxima-nova';
}
h6 {
	// font-family: 'proxima-nova';
}
h4 {
	// font-family: "Inter-Medium";
	&.bold {
		// font-family: "Inter-ExtraBold";
	}
	&.sm-bold {
		// font-family: "proxima-nova";
		font-size: 14px !important;
	}
}
.pb-50 {
	padding-bottom: 50px;
}

ul {
	&.two-column{
		columns: 2;
		-webkit-columns: 2;
		-moz-columns: 2;
		li{
			font-size: 14px;
			.list-label {
				color: $textgray;
				margin-right: 15px;
				min-width: 105px;
			}
			.list-value {
				color:$primary-color;
			}
		}
	
	}
	&.three-column{
		columns: 3;
		-webkit-columns: 3;
		-moz-columns: 3;
		li{
			font-size: 14px;
			.list-label {
				color: $textgray;
				min-width: 105px;
			}
			.list-value {
				color:$primary-color;
			}
		}
	
	}
  }
/** Title **/
.content-title {
	text-align: center;
	padding: 60px 0px 30px 0px;
	.title-box {
		.title {
			display:inline-block;
			position: relative;
			// font-family: 'proxima-nova';
			margin-bottom: 5px;
			font-size: 1.8rem !important;
			color: #FBCB0C;
			// span {
			// 	background-color: $theme-bg;
			// }
			// &:after {
			// 	content:"";
			// 	width:100%;
			// 	position: absolute;
			// 	bottom:2px;
			// 	height:2px;
			// 	background:#000;
			// 	left:0;
			// 	z-index: -1;
			// }

		}
		.sub-title {
			// font-family: 'Inter-Regular';
			color:#a7a7a7;
			font-size: 1.3rem !important;
		}
	}
}


.row-fluid {
	display: flex;
	flex-flow: wrap;
}
.sub-heading{
	text-transform: uppercase;
    font-size: 17px;
    // font-family: 'Inter-Medium';
    margin-bottom: 20px;
	cursor: pointer;
}


/**Product box one **/
.product-box-one {
	.product-detail {
		background-color: #fff;
		padding:10px;
		position: relative;
		border: 2px solid #eceaea;
		.product-name {
			padding-right:30px;
		}
		.product-option {
			.wishlist {
				.add-to-wishlist {
					position: absolute;
					top: 0px;
					right: 0px;
					font-size: 22px;
				}
			}
		}
	}
}
/**product**/

.product-name {
	font-size: 18px;
}
h6 {
	&.product-name {
		font-size: 19px;
	}
}

/***Listing**/
.collection-wrapper {
    .collection-content {
      background-color: #fff;
    }
    padding-top: 30px;
    padding-bottom: 30px;
    .collection-top {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .collection-filter {
      background-color: #fff;
      .filter-top {
        border-bottom: 1px solid #dddddd;
        .filter-clear {
          padding: 10px;
          display: flex;
          justify-content: space-between;
        }
      }
      .layerd-navigation {
        padding: 10px;
        // font-family: "Inter-Regular";
		border-bottom: 1px solid #dddddd;
		.collection-collapse-block {
			margin-bottom: 10px;
			
		.collection-collapse-block-content {
			padding-left: 30px;
			
			.options {
                margin: 8px 0px 8px 0px;
                input {
                    margin-right: 10px;
                }
                label{
                    margin-bottom: 0px;
                    font-size: 15px;
                    .count {
                        color: #afabab;
                        font-size: 14px;
                    }
                }
            }
		}
	  }
	}
    }
  
    .col-grid-box {
      width: 100%;
      background: #fff;
      padding: 0px 10px 0px 10px;
    }
  
    .sortby {
      .input-group-text {
        background-color: #fff;
        border-radius: 0;
        border-right: 0;
      }
      .sortby-options {
        border-left: 0px;
        border-radius: 0;
      }
    }
  }
  /** Product Details**/
  
.product-details-wrapper {
    .details-top {
        padding: 15px 0px 15px 0px;
    }
    .slider-nav {
        max-width: 400px;
        min-width: 300px;
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
        
    .owl-thumb {
        img{
            opacity: 0.5;
        }
        background-color: #000;    
        cursor: pointer;
        &:hover {
            background-color: transparent;
            border:2px solid #f7f1f1;
            img {
                opacity: 1;
            }
        }
        &.active {
            
            border:2px solid #f7f1f1;
            background-color: transparent;
            img {
                opacity: 1;
            }
        }
    }
    }
    .product-all-details {
        .product-name {
            font-size: 30px;
        }
    }

    .details-wrapper {
        .product-all-details {
            background-color: #fff;
            padding: 0px 15px 15px 15px;
            .price {
				font-size: 24px;
			}
            .row {
                padding:15px;
                .col-left {
                    flex: 1;
                    padding-right: 15px;
                }
                .col-right{
                    min-width: 250px;
                     .add-to-cart {
                        margin-bottom: 30px;
                    }
                    .delivery-option {
                        margin-top: 30px;
                        h6 {
                            .fa {
                                    font-size: 22px;
                                    -webkit-transform: scaleX(-1);
                                    transform: scaleX(-1);
                            }
						}
						.input-group {
							input {
								border-right:0px;
							}
							.input-group-text {
								background-color: #fff;
								border-left:0;
								color:$primary-color;
							}
						}
						
                    }
                   
                }
            }
            
            .description{
                padding-bottom: 15px;
            }
            .product-addtional-details {
                position: relative;
                padding-top: 15px;
                &::before {
                    content: "";
                    position: absolute;
                    width: calc(100% - 198px);
                    height: 2px;
                    background: #707070;
                    opacity: 0.2;
                    box-shadow: 0;
                    top: -10px;
                }
            }
        }
        
    }
    
}

.ng5-slider {
	.ng5-slider-pointer {
		cursor: pointer;
		width: 20px!important;
		height: 20px!important;
		top: -8px!important;
		background-color: #fff !important;
		z-index: 3;
		border-radius: 16px!important;
		box-shadow: 2px 3px 9px 2px rgba(0,0,0,0.56);
		-webkit-box-shadow: 2px 3px 9px 2px rgba(0,0,0,0.56);
		-moz-box-shadow: 2px 3px 9px 2px rgba(0,0,0,0.56);
	}

	 .ng5-slider-span:focus{
		outline: 0;
	}
	.ng5-slider-pointer:after {
		display: none !important;
	}
	.ng5-slider-selection {
		z-index: 2;
		background: $primary-color !important;
		border-radius: 2px;
	}
}

/** Pages **/

.homepage-banner-slider {
  .owl-theme {
    .owl-dots { 
      position: absolute;
			bottom: 15px;
			left: 50%;
	}
	.owl-next,
	.owl-prev {
		position: absolute;
		top:50%;
		transform: translateY(-50%);
		background-color: $theme-bg !important;
		border: 0px !important;
		.fa {
			font-size: 30px;
		}
	}
	.owl-next{
		right:0;
		background:$theme-bg;
		width:20px;
	}
	.owl-prev{
		left:0;
		background:$theme-bg;
	}
  }
}
.rounder-corner-nav {
	&.top-nav-right{
		.owl-theme {
			.owl-nav {
				position: absolute;
				top: -70px;
				right: 0;
			}
	}
}
&.top-nav-bg-white{
	.owl-theme {
		.owl-nav {
			margin-top:0px;
			.owl-prev,
			.owl-next {
					background: #fff;
					border: 0px;
					.fa {
						color:#000;
						
					}
			}
		}
}
}
	.owl-theme {
		.owl-nav {
			margin-top:0px;
			.owl-prev,
			.owl-next {
				border-radius: 20px;
				width: 40px;
				height: 40px;
				background: transparent;
				border: 2px solid #b7b6b5;
				.fa {
					color:#b7b6b5;
					font-size: 26px;
				}
			}
		}
	}
}
.rotate-dot{
	.owl-theme {
		.owl-dots {
		
			transform: translateX(-50%);
			[class*="owl-"] {
				width: 25px;
				height: 25px;
				transform: rotate(130deg);
				margin: 0px 6px;
				border: 1px solid transparent;
				&.active {
					border: 1px solid $primary-color;
					span {
						background-color: $primary-color;
					}
				}

				span {
					width: 12px;
					height: 12px;
					border-radius: 0;
					margin: 5px 5px;
				}
			}
		}
	}
}


.featured-collections {
  .tab-image {
          min-height: 300px;
          text-align: center;
          position: relative;
          margin-bottom: 30px;
          cursor: pointer;
          overflow: hidden;
          position: relative;
          .background-image {
            height: 100%;
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            -webkit-transition: all .5s;
            -moz-transition: all .5s;
            -o-transition: all .5s;
            transition: all .5s;
                min-height: 300px;
                &:hover {
                  transform: scale(1.2);
                }
          }
          .bg-content{
            position: absolute;
            top:50%;
            left:50%;
			transform: translate(-50%, -50%);
			 color:#fff;
            font-size: 25px;
           
			  p{
				  font-size: 15px;

			  }
           
          }
          
  }
}


.best-products {
  background: #fff;
  padding-top: 50px;
  padding-bottom: 30px;
  .title {
	  text-align: center;
	  padding-bottom: 20px;
	   h2 {
		// font-family: 'proxima-nova';
	}
  }
  .column {
      
      -webkit-flex: 1; /* Safari */
      -ms-flex: 1; /* IE 10 */
      flex: 1; /* Standard syntax */
      
      &.grid-image {
          -webkit-flex: 2; /* Safari */
          -ms-flex: 2; /* IE 10 */
          flex: 2; /* Standard syntax */
      }
      &.single-image {
          border: 1px solid #c5c5c5;
          margin-bottom:20px;
          text-align: center;
          .trers-single-image {
              img {
                  min-width: 300px;
              }
          }
          h4 {
              font-size: 20px;
              text-transform: uppercase;
          }
      }
      .tyers-grid {
          display: flex;
          flex-flow: wrap;
          margin: 0;
          li {
              width:calc(50% - 20px);
              border: 1px solid #c5c5c5;
              margin: 0px 20px 20px 0px;
              padding:10px;
              display: flex;
			  align-items: center;
			  .trers-grid-image {
				  img {
					  padding: 10px;
				  }
			  }
              a {
				cursor: pointer;
				display:block;
              }
              &:hover {
                border-color: var(--theme-deafult);
              }
              h4 {
                  font-size: 20px;
                  text-transform: uppercase;
              }

          }
      }
    
      
  }
 
}
.mobile-cart{
	display: none !important;
}

.mobile-only{
	display: none !important;
}
.home-featured-collections{
	margin-left:100px;
}

.shubhcards-featured-title{
	font-size: 1.4rem;
	text-align:center;
	font-family: Helvetica; 
	text-transform: uppercase !important; 
	font-weight: bold;
	color:black;
}
.price-color{
	color:grey!important;
}

.rating-color{
	color: #fe6602!important;
	
}
.border-color{
	border-color: #fe6602!important;
	margin-top: 2rem!important;
	margin-bottom: 2rem!important;


}
.border-color1{
	border-color: #fe6602!important;
	margin-top: 2rem!important;
	// margin-bottom: 2rem!important;


}
.moq-color{
	color:grey!important;

}
#product_breadcrumbs{
	padding-left: 90px!important;
}
.padding-top-bottom{
	margin-top: 20px !important;
    
    margin-bottom: 20px;
}
@media screen and (max-width:600px) {
	.image-container-list {
		//border: 1px solid;
		//border-radius: 10px;
		height: 400px!important; /* Set the desired height */

		// position: relative;
		// overflow: hidden;
		// box-shadow: 0 2px 14px #0000000a, 0 4px 14px #00000021;
	  }
	#product_breadcrumbs{
		padding-left: 0px !important;
	}

	.tabs-vim {
		position: relative;
		margin: 3rem 0;
		background: white;
		display: flex;
		justify-content: center;
		// height: 8.75rem!important;
		flex-wrap: wrap!important;
	}
	.tabs-vim-theme {
		position: relative;
		margin: 3rem 0;
		background: white;
		display: flex;
		justify-content: center;
		height: 8.75rem!important;
		flex-wrap: wrap!important;
	}
	.margin-space{
		margin-left:3%;
	}
	.pro_list_color{
		font-size: 13px;

	}.tab-label-vim{
		padding: 0 0.618em!important;
	}

	.shubhcards-featured-title{
		font-size: 1rem !important;
	}

	.home-featured-collections{
		margin-left:-15px !important;
		width: 120% !important;
	}
	
	.shubhcards-container{
		width:99%;
		margin-left: -6%;
	}
	.new-shub-grid{
		display: grid;    grid-template-columns: repeat(1, 1fr)!important;
		margin-left: 6%!important;
		grid-row-gap: 70px!important;
	  }

	.content-title {
		text-align: center;
		padding: 20px 0px 30px 0px;
	}
	.featured-collections {
		.tab-image {
				min-height: 104px !important;
				height:100px;
				text-align: center;
				position: relative;
				margin-bottom: 30px;
				cursor: pointer;
				overflow: hidden;
				position: relative;
				.background-image {
					position: relative !important;
				  min-height: 180px;
				  width: 200%;
				  margin-left: -50%;
				  margin-top: -20%;
				 transform: scale(0.5);
				  background-repeat: no-repeat;
				  -webkit-transition: all .5s;
				  -moz-transition: all .5s;
				  -o-transition: all .5s;
				  transition: all .5s;
					  &:hover {
						transform: scale(1.2);
					  }
				}
				.bg-content{
				  position: absolute;
				  top:50%;
				  left:50%;
				  transform: translate(-50%, -50%);
				   color:#fff;
				  font-size: 25px;
				 
					p{
						font-size: 15px;
					}
				 
				}
				
		}
	  }
	  

	  .rounder-corner-nav {
		&.top-nav-right{
			.owl-theme {
				.owl-nav {
					position: absolute;
					top: -70px;
					right: 0;
				}
		}
	}
	&.top-nav-bg-white{
		.owl-theme {
			background-color: transparent !important;
			.owl-nav {
				margin-top:0px;
				background-color: transparent !important;
				.owl-prev,
				.owl-next {
						background: transparent !important;
						border: 0px;
						.fa {
							color:#000;
							
						}
				}
			}
	}
	}
		.owl-theme {
			.owl-nav {
				margin-top:0px;
				z-index: -100;
				position: absolute;
				top:45%;
				display: flex;
				width: 100%;
				text-align: center;
				justify-content: center;
				top:45%;
				.owl-next {				
					position: relative;
					margin-left: 85%;
					width: 40px;
					height: 40px;
					background: transparent;
					border: none !important;
					background-color: transparent !important;
					.fa {
						color:black;
						font-size: 40px;
					}
				}
				.owl-prev{
					position: relative;
					margin-left: -5%;	
					width: 40px;
					height: 40px;
					background: transparent;
					border: none !important;
					background-color: transparent !important;
					.fa {
						color:black;
						font-size: 40px;
					}
				}
			}
		}
	}

	.shubhcards-image{
		width: 55% !important;
		min-height: 0px !important;
	}
	.shubhcards-image-container{
		height:118px;
		zoom: 1.2;
	}
	.shubhcards-card{
		min-height: 0px !important;
		display: flex;
		width:97% !important;
		height: 95%;
	}
	.shubhcards-price{
		float: right;
		width: 100% !important;
	}
	.shubhcards-cart-btn{
		float: right;
	}
	.shubhcards-view-cart{
		width: 102% !important;
		position: absolute;
		top: 90%;
		left: -6%;
	}
	.shubhcards-description{
		width: 47% !important;
	}
	.shubhcards-product-title{
		float:right;
		text-align:right !important;
		font-size: 15px !important;
	}
	.viewdetails-btn{
		position: absolute;
		top: 23%;
		left: 25%;
		width: 50%;
	}
	.shubhcards-btn{
		position: absolute;
		top: 92%;
		left: 22%;
    	width: 76%;
	}
	.sc-title{
		white-space: nowrap;
    	text-overflow: ellipsis;
    	overflow: hidden;
	}
	.desktop-title-grid{
		font-size:15px !important
	}

	.mobile-cart{
		display: flex !important;
		float:right;
	}

	.desktop-carousel{
		display: none !important;
	}
	.desktop-only{
		display: none !important;
	}

	.mobile-only{
		display: block !important;
	}

	.mobile-options{
		position: absolute !important;
    	margin-top: -62px !important;
    	margin: 0;
    	margin-left: 160px;
    	left: 0;
    	border: none !important;
	}
	.mob-eye{
		margin-left:40px
	}
	.mob-second{
		margin-top:-31px
	}
	.forgot-link{
		margin-top:-26px !important
	}
	.forgot-link-checkout{
		margin-top:-45px !important
	}
	.back-btn{
		width:35% !important;
		margin-left:65%;
	}
	.submit-btn{
		width: 35% !important;
	}
	
	.scale-9{
		transform: scale(0.9);
	}
	.scale-8{
		transform: scale(0.8);
	}
	.mob-logo{
		transform: scale(.7);
		top: 5px!important;
		left: 25%;
		position: fixed;
		z-index: 3;
		width: 50%;
		object-fit: contain;
	}
	.earthy-card{
        margin: 15px !important;
    }
}

.subhcards-feature-collections{
    // background-color: #272829;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.shubcards-tabimage{
    border: 1px solid #CC5500 !important;
    border-radius: 20px;
 }

.shubhcards-card{
    margin: 5px !important;
    min-height: 350px;
    max-height: 350px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
	// width: 300px;
    
}

.shubhcards-image{
    min-height: 220px;
    max-height: 220px;
    padding: 10px;
}

.viewdetails-btn{
	padding: 5px 20px !important;
	border: none !important;
	font-size: 16px !important;
}
.shub_rowlist{
	padding-left: 0px !important;
}
.shubhcards-description{
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    // padding: 10px;
    min-height: 130px;
    max-height: 130px;
    background-color: $white;
	// width: 299px;
}

.shubhcards-product-title{
    color: $primary;
    font-weight: 500;
    font-size: 16px;
	width: 280px !important;
}

.shubhcards-price{
    padding-top: 5px;
    font-weight: 500;
    font-size: 15px;
	width: 280px;
}
@media  screen and (min-width:601px) {
	.shubhcards-view-cart{
		width: 70%;
		display: inline-block;
	}
	.desktop-title-grid{
		width:280px; 
		font-size:15px !important
	}
    .shubhcards-card:hover{
        min-height: 350px;
        max-height: 350px;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
        .shubhcards-image{
            min-height: 190px;
            max-height: 190px;
            padding: 10px;
            object-fit: contain !important;
        }
        .shubhcards-description{
            box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
            min-height: 160px;
            max-height: 160px;
        }
        .shubhcards-product-title{
            margin-top: 5px;
            font-size: 14px;
        }
        .shubhcards-cart-btn{
            padding-top: 10px;
            display: block;
			font-size: 13px !important;
			font-weight: 500 !important;
			width: 280px !important;
        }
		.shubhcards-view-cart{
			width: 70%;
			display: inline-block
		}
    }
    
}
@media screen and (max-width:376px) {
	.desktop-title-grid{
		font-size:14px !important
	}
}
.justify-content-center {
    justify-content: center !important;
    width: 100% !important;
}
.vimpro-nav.shubhcards-nav {
    margin-top: -6.6% !important;
    width: 50%;
}

// .shubhcards-logo{
// width: 17vw;
//     height: 5vw;
//     margin-top: 2%;
//     margin-left: 2%;
// }
.logo-image.custom-pointer.mob-logo{
	width: 17vw;
    height: 5vw;
    margin-top: 2%;
    margin-left: 2%;
}
.shubhcards-header{
	margin-top: 8% !important;
	border-bottom: none !important;
}
.transparent-header {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
   
    z-index: 999;
}

.homepage-banner-slider {
    position: relative; 
	margin-top: -8rem;
	margin-bottom: -3rem;
}
.account-section{
	margin-right: 4rem;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: transparent; 
    filter: brightness(0) saturate(100%) invert(46%) sepia(55%) saturate(4238%) hue-rotate(3deg) brightness(98%) contrast(94%);
	width: 30px !important;
	height: 30px !important;
}
/* Style for the ShubhCards-homepage-grid section */
.homepage-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    object-fit: cover;
    width: 100%;
}

header .main-navbar .nav-menu>li .nav-submenu{
	margin-left: -34px !important;
}
  .grid-item img {
	width: 100%; 
	height: auto; /* Maintain aspect ratio */
  }
  
  .ShubhCards-homepage-grid {
    overflow: hidden;
    width: 100%;
    /* margin-left: -7%; */
    /* margin-top: -5.7rem; */
}




  .grid-item {
	position: relative;
  }
  
  .overlay-image {
	position: relative;
	z-index: 1;
  }
  
  .owl-next-button {
    position: absolute;
    top: 62%;
    right: 67%;
    transform: translateY(-50%);
	border: 3px solid rgb(255 168 28);
    border-radius: 50%;
    /* padding: 11px; */
    background: none;
    z-index: 2;
	padding: 5px 16px 8px 19px;
}
.owl-next-button.elite{
    position: absolute;
    top: 61%;
    right: 66%;
    transform: translateY(-50%);
	border: 3px solid rgb(255 168 28);
    border-radius: 50%;
    /* padding: 11px; */
    background: none;
    z-index: 2;
	padding: 5px 16px 8px 19px;
}

.grid-item.video {
    position: relative;
}

.video-controls {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.play-pause-icon {
    font-size: 2rem; 
    color: white; 
    cursor: pointer;
}


.video-controls.clicked + .overlay-image {
    display: none;
}
.video-controls.clicked .play-pause-icon {
    opacity: 0; 
}

.overlay-image.video-image1{
	position: absolute;
	width:100% !important; 
	height: 45rem;
	margin-top:-1rem;
	object-fit:cover;
	margin-left: 0rem;
	z-index: 1;
}









.owl-next-button.luxury{
    position: absolute;
    top: 62%;
    right: 64%;
    transform: translateY(-50%);
	border: 3px solid rgb(255 168 28);
    border-radius: 50%;
    /* padding: 11px; */
    background: none;
    z-index: 2;
	padding: 5px 16px 8px 19px;
}
  
  .owl-next-button i {
	color:rgb(255 168 28);
	font-size: 30px; 
	margin-top: 3px;
  }
  
  .header {
    background: #fff;
    border-bottom: none !important;
}
.navbar-row {
     box-shadow: none !important;
}
.overlay-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 25% !important;
    height: auto;
    object-fit: cover;
    /* opacity: .5; */
    margin-left: 10rem;
	margin-top: 11%;
}
.overlay-image.video-image{
	position: absolute;
    top: 0;
    left: 0;
    height: 30%;
  
    margin-left: 29rem;
    margin-top: 20%;
}


.footer-content-Shubhcards{
width: 151%;
    font-size: 14px;
    margin-top: 1rem;
}

.footer-col.Shubhcards{
    max-width: 200px;
    margin-left: -2rem !important;
}

.footer_bgcolor.shubhcards{
	background-color: rgba(204,0,1,255);
	
}

.footer-links .footer-col.address i {
    color: #fdc66d !important;
    margin-right: 5px;
}
.social-icons {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    margin-top: 1.2rem;
    margin-left: 5.3rem;
}


.social-icons li {
    margin-right: 18px;
}
.social-icon-circle {
    width: 26px;
    height: 26px;
    background-color:#ff6500;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-icon-circle a {
    display: inline-block;
}

.social-icon-circle a .icon i {
    color: #fff !important;
    font-size: 14px;
    margin-left: 0.2rem;
}
.footer-row-two{
	background-color: #ff6f00;
    margin-top: -1rem;
	height:50px;
}
.poweredbylogo {
    position: relative;
    top: 80px !important;
    width: 9%;
    float: right;
    margin-top: -4%;
    margin-right: 5%;
    object-fit: contain;
}
.pwrdby {
    position: relative;
    top: 79px;
    right: 100px;
    width: 9%;
    float: right;
    margin-top: -3% !important;
    margin-right: 4% !important;
    object-fit: contain;
}
.container-fluid.footer.footer_bgcolor.shubhcards {
	margin-top: -8.1rem;
}
.container-fluid.footer.footer_bgcolor.shubhcards .container{
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.copyright{
margin-top: -1.2rem;
    color: whitesmoke;
    margin-left: 2.3rem;
}

footer .footer-links .footer-col ul li {
    font-size: 0.9rem;
    margin-bottom: 3px;
}

// .owl-next {
//     position: absolute;
//     top: 70%;
//     right: 48%;
//     transform: translateY(-50%);
//     border: 2px solid rgb(255, 255, 255);
//     border-radius: 50%;
//     background: none;
//     z-index: 2;
//     padding: 7px 13px 7px 15px;
// }
  
//   .owl-next i {
// 	color:rgb(255, 255, 255);
// 	font-size: 30px; 
// 	margin-top: 2px;
//   }

  .owl-next-video {
    position: absolute;
    top: 33%;
    right: 48%;
    transform: translateY(-50%);
    border: 2px solid rgb(255, 255, 255);
    border-radius: 50%;
    background: none;
    z-index: 2;
    padding: 4px 16px 2px 23px;
}
  
  .owl-next-video i {
	color:rgb(255, 255, 255);
	font-size: 50px; 
	margin-top: 2px;
  }
  .row.contact-us{
	justify-content: space-between;
background-color: white;
border: 4px solid rgb(233, 233, 233);
padding: 15px 25px !important;
width: 100% !important;
margin-left: -7px !important;
	
  }
  .logo-image.custom-pointer.contact{
	height: 48px !important;
    margin-left: -14px !important;
  }
  .member-contacts.padding_section{
	margin-top: 12px !important;
  }
  .account-popup .popup-content {
    overflow-y: auto;
    overflow-x: hidden !important;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 370px;
    padding: 15px;
    min-width: 300px;
}
//   header .main-navbar .nav-menu>li a {
// 	padding: 15px;
// 	color: #ffe9c1 !important;
// }
// .account-section{
// 	color: #ffe9c1 !important;
// }
.nav-menu li a{
	color: #f19148 !important;
}
.account li a{
	color: #f19148 !important;
}
.image-container {
	display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
  }
  .image-container img {
	max-width: 100%;
	height: auto;
  }
  .centered-text {
	position: absolute;
	// top: 50%;
	// left: 50%;
	// transform: translate(-50%, -50%);
	color: white;
	text-align: center;
	// background-color: rgba(0, 0, 0, 0.5); /* Optional: add background color with transparency for better text readability */
	padding: 10px;
	border-radius: 10px; /* Optional: add border-radius for rounded corners */
  }
  .dancing-script {
	font-family: 'Dancing Script', cursive;
  }
  .align-center{
	margin-right: auto;
	margin-left: auto;
  }
  .tab-switch-vim:checked+.tab-label-vim{
	color: #ff6600!important;
  }
  .tab-label-vim{
	color: #776d6d!important;

  }
  .pro_detail{
	min-height: 10vh!important;
  }
  .new-shub-grid{
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	// border: 2px solid orangered;
	grid-row-gap: 7px;
	// grid-column-gap:50px;
  }
  .image-container-list {
	//border: 1px solid;
    //border-radius: 10px;
	height: 700px; /* Set the desired height */
	// position: relative;
	// overflow: hidden;
	box-shadow: 0 2px 14px #0000000a, 0 4px 14px #00000021;
  }
  
  .image-container-list img {
	// max-width: 100%;
	// max-height: 100%;
	object-fit: contain; /* or contain */
  }

        .nav-link1.active {
//   background-color: #007bff;
  color: #f19148;
  .default-tab{
	color: #776d6d !important;
  }
}
    
.border-shub{
	border-radius: none!important;
}
::ng-deep .pad-top{
	// text-align: justify;
	 margin-top: -5px;
	 border-color:unset!important;
	 position: relative;
	
	 top: 4px;
	height: 40px;
	background-color: #6b6b6b!important;
	border-radius:5px!important;
	display: flex;
    justify-content: space-between;
}
.shub-d-flex{
	display: flex !important;
}
.align-s-rgt{
	margin-left: 10%;
}
.card-registration .select-input.form-control[readonly]:not([disabled]) {
	font-size: 1rem;
	line-height: 2.15;
	padding-left: .75em;
	padding-right: .75em;
	}
	.card-registration .select-arrow {
	top: 13px;
	}
	.gift-form{
		background-color: transparent!important;
		box-shadow:none!important;
	}
	.modal-content{
		border-radius: 10px;
		

	}
	.alert{
		width: 58%!important;
		margin-left: -5%
	}
	.dropdown-menu.show{
	position: absolute;
    inset: 0px 0px auto auto;
    margin: 0px;
    transform: translate3d(-45.2px, 40px, 0px)!important	;
	}

	/* In styles.css or styles.scss */
	body :host ::ng-deep.modal-dialog-centered  .modal-dialog-scrollable::-webkit-scrollbar {
	width: 8px !important; /* Width of the entire scrollbar */
  }
  
  body :host ::ng-deep.modal-dialog-centered  .modal-dialog-scrollable::-webkit-scrollbar-track {
	background: #f1f1f1 !important; /* Background of the scrollbar track */
  }
  
  body:host ::ng-deep.mmodal-dialog-centered .modal-dialog-scrollable::-webkit-scrollbar-thumb {
	background-color: #888 !important; /* Color of the scrollbar thumb */
	border-radius: 10px !important; /* Rounded corners */
	border: 2px solid #f1f1f1 !important; /* Space around thumb */
  }
  
 body :host  ::ng-deep.modal-dialog-centered .modal-dialog-scrollable::-webkit-scrollbar-thumb:hover {
	background-color: #555 !important; /* Change color on hover */
  }
  
 body :host ::ng-deep.modal-dialog-centered .modal-dialog-scrollable {
	scrollbar-width: thin !important; /* Thin scrollbar for Firefox */
	scrollbar-color: #888 #f1f1f1 !important; /* Thumb and track colors for Firefox */
  }
  
	