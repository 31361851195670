@media (min-width: 1300px) {
	.container {
	  max-width: 1280px;
	}
  }
  
  /*@media (min-width: 992px) {
	  .navbar-toggler {
		  display: none;
	  }
	  .btn.close-btn {
		  display: none;
	  }
	  
  }*/
  @media (min-width: 991px) {
	.trers-grid-image {
	  img {
		max-width: 150px;
	  }
	}
  }
  
  @media (max-width: 1199px) {
	footer{
        .footer-links {
            display: block;
        }
    }
	.free-shipping-section {
	  ul {
		flex-flow: wrap;
		li {
		  margin-bottom: 15px;
		}
	  }
	}
	body {
	  padding-top: 47px;
	}
  
	.header {
	  .flex-container {
		.header-row-one {
		  display: block;
		  .account-section {
			position: absolute;
			top: 0;
			width: 100%;
			border-bottom: 1px solid #00000012;
			left: 0;
			z-index: 99;
			background: #fff;
			.account {
			  justify-content: flex-end;
    		 margin-top: 10px;
			}
		  }
		}
	  }
	}
  }
  @media (max-width: 991px) {

	.product-details-wrapper {
		.slider-nav {
			bottom:6px;
			
		}
		.details-wrapper {
			
			.product-all-details {
				.row {
					.col-left {
						flex: auto;
					}
				}
				.product-addtional-details{
					padding-bottom:15px;
					&:before {
						width:100%;
					}
				}
			}
		}
	}
	.banner-content {
		.content {
		  h2, h1 {
			font-size: 30px;
		  }
		}
	  }
	/**Product**/
	.product-box-inner {
	  .product-image {
		max-width: 150px;
	  }
	  .product-detail {
		.price-wrapper {
		  position: inherit;
		  transform: none;
		}
		.add-to {
		  position: inherit;
		}
	  }
	  .verified {
		top: 10px;
	  }
	}
  
	.best-products {
	  .row-fluid {
		display: block;
		.tyers-grid {
		  justify-content: space-between;
		  li {
			display: inline-block;
			margin: 15px 0px 15px 0px;
			text-align: center;
		  }
		}
	  }
	  .column {
		&.single-image {
		  margin-top: 15px;
		}
	  }
	}
  }
  
  @media (max-width: 767px) {

	.mob-filter{
		
		z-index: 200;
		background-color: $bg-color;
		width: 100%;
		top: 0;
    	bottom:0;
		left:0;
    	position:fixed;
    	overflow-y:scroll;
    	overflow-x:hidden;
	}

	.filter-button{
		display: block !important;
		position: fixed;
    	bottom: 4%;
    	left: 45%;
   		width: 50%;
    	height: 8%;
		border-radius: 50px;
		border: 2px solid $primary;
		font-size: 18px;
		font-weight: 600;
		-webkit-box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
		-moz-box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
		box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
	}

	.details-wrapper {
		padding-right: 0px !important;
    	padding-left: 0px !important;
    	margin-right: 0px !important;
    	margin-left: 0px !important;
		.slider-nav {
			bottom:6px;
			max-width: 240px;
			min-width: 230px;
		}
	}
	.banner-content {
		.content {
		  h2, h1 {
			font-size: 20px;
		  }
		}
	  }
	.aside-group {
	  display: block;
	}
  
	/**Title**/
	.content-title {
	  .title-box {
		.title {
		  &:after {
			display: none;
		  }
		}
	  }
	}
  
	.header {
	  .flex-container {
		display: block;
		.logo {
		  text-align: center;
		  padding: 15px;
		  margin:0px auto;
		}
		.header-row-one {
			.search-bar-group {
				.search-bar {
					margin-top:10px;
					margin-right: -4rem;
					width:100% !important;
					
				}
			}
		}
	  }
	}
  
	.content-1-section {
	  position: static;
	  border-radius: 0px;
	}
	.homepage-banner-slider {
	  .owl-theme {
		.owl-dots {
		  bottom: 0;
		}
	  }
	}
  
	.best-products {
	  .column {
		.tyers-grid {
		  li {
			display: block;
			text-align: center;
		  }
		}
	  }
	}
  }
  @media (max-width: 640px) {

	.checkout-btn{
		min-width:fit-content; 
		padding-left:0px !important; 
		height:30px !important;
		position: absolute;
		top: 0px;
		width: 150px !important;
		margin-top: 35%;
		left: -65%;
    	margin-right: 10%;
	}
	
	.collection-wrapper {
	  .col-grid-box {
		padding: 0px 10px 0px 10px;
	  }
	}
	
	footer{
        .footer-links {
            display: grid !important;
        }
		.footer-col{
			justify-content: center !important;
			padding: 5px;
			margin:5px;
			min-width:136%;
		}
    }

	.banner-content {
	  .content {
		h2, h1 {
		  font-size: 18px;
		}
	  }
	}
	.header {
	  .flex-container {
		.header-row-one {
		  .search-bar {
			margin-left: 0px;
		  }
		  .account-section{
			position: fixed !important;
			z-index: 2 !important;
			padding-right:0px;
			height:50px;
			.account{
				margin-right: 10px !important;
			}
			.dropdown-toggle{
				font-size:10px !important;
			}
			.fa-user{
				font-size:10px !important
			}
		  }
		}
	  }
	}


	.product-box-inner {
	  display: block;
	  padding: 10px 0px 10px 0;
	  .img-wrapper {
		padding-bottom: 10px;
		.product-image {
		  max-width: 100%;
		  width: 100%;
		}
	  }
  
	  .product-detail {
		padding-left: 0px;
	  }
	  .verified {
		top: 10px;
		transform: none;
	  }
	}
  
	/*Pagenation**/
	.product-pagination {
	  .row {
		display: block;
		> div {
		  margin-bottom: 10px;
		  text-align: center;
		  p {
			margin-bottom: 0px;
		  }
		  .pagination {
			justify-content: center;
		  }
		}
	  }
	}
	.mobile-filter{
		display: block !important;
	  }
	.desktop-filter{
		display : none !important;
	}
	.mob-categories{
		
		opacity: 1;
		padding:10px;
		font-family: 'proxima-regular','sans-serif'!important;
		position: fixed;
		background-color: white;
		z-index: 100;
		width: 100%;
		height: 100%;
		border-right: 50px solid rgba(0, 0, 0, 0.5);
		overflow-y: auto;
		top:0;
		left:0;
		background-clip: padding-box;
		
		
	}
	.touch-out{
		 background-color:transparent;
		 position: fixed;
		 z-index: 999;
		 width: 60px;
		 right: 0px;
		 height:300%;
		 top:0px;
		 overflow:hidden;
		
	}
	// body{
	// 	position: fixed !important;
	// }

  }
  /*** Range **/
  
  @media (max-width: 991px) and (min-width: 640px) {
	.product-name {
	  font-size: 18px;
	}
	.price {
	  font-size: 18px;
	}
  
	.product-price {
	  .tax-includes {
		font-size: 13px;
	  }
	}
	.verified {
	  font-size: 13px;
	}
	.add-to {
	  .btn-solid {
		font-size: 15px;
	  }
	}
  }
  
  @media (max-width: 640px) {
	.logo-image.custom-pointer.mob-logo{
		width: 30vw !important;
        height: 15vw !important;
        margin-top: 9%;
        margin-left: 24%;
	}
	header .main-navbar .nav-menu {
        position: fixed;
        background-color: #fff;
        width: 300px;
        border-radius: 0;
        border: 1px solid #eee;
        top: 0;
        height: 104vh !important;
        left: -300px;
        z-index: 99;
        transition: all .3s ease;
        overflow: scroll;
    }

	.account.mobile-cart{
		margin-left: 51px !important;
		margin-top: -22% !important;
	}
	.header .flex-container .header-row-one .account-section .account {
        margin-right: 8px !important;
        margin-top: 3%;
        margin-left: 12%;
    
    }
	.header .flex-container .header-row-one .account li {
		padding: 0px 11px;
		margin-top: 2%;
	}
	.header .flex-container .header-row-one .account li {
		padding: 0px 12px !important;
		margin-right:0px;
	}
	.toggle-nav {
		position: fixed !important;
		cursor: pointer;
		left: 20px !important;
		top: 16px !important;
		z-index: 99;
	}
    .mobile-banner-img{
		height: 15rem !important;
        object-fit: cover;
        margin-top: 3rem !important;
	}
    .grid-item img{
		width: 100%;
        height: 14rem !important;
        object-fit: cover !important;
        margin-top: -1rem;
	}
	.grid-item .overlay-image {
        width: 100%;
        height: 4.5rem !important;
        margin-top: 3rem;
        margin-left: 2rem;
    }
	.grid-item.overlay-image.video-image{
		width: 100%;
        height: 4.5rem !important;
        margin-top: 3rem;
        margin-left: 5rem !important;
	}
	.overlay-image.video-image {
		position: absolute;
		top: 15px !important;
		left: 0;
		width: 60% !important;
		height: auto;
		object-fit: cover;
		margin-left: 5rem !important; 
		margin-top: 20%;
	}

	.grid-item.video{
	width: 100%;
    height: 19rem;
   
    object-fit: cover;
	}
	.align-bottom{
		margin-bottom: 9%;
	}
	.homepage-banner-slider {
		position: relative;
		margin-top: -2rem !important;
		margin-bottom: -3rem;
	}
	.overlay-image.video-image1 {
		position: absolute;
		width: 100% !important;
		height: 100% !important;
		margin-top: -1rem;
		object-fit: cover;
		margin-left: 0rem;
		z-index: 1;
	}

	.social-icons {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		margin-top: 1.2rem;
		margin-left: 1.8rem !important;
	}

	.contact-us-login.shubhcards{
		text-align: center;
		background-color: white;
		border: 4px solid rgb(233, 233, 233);
		padding: 15px 10px;
		margin-left: -22px !important;
		margin-top: 10px !important;
	  }
	  .row.contact-us{
		justify-content: space-between;
    background-color: white;
    border: 4px solid rgb(233, 233, 233);
    padding: 15px 16px !important;
    width: 99% !important;
    margin-left: -7px !important;
		
	  }
	  .col-md-10 h6{
        margin-left: -15px !important;
		margin-top: 10px;
	  }

	  .text-muted.shubhcards{
		margin-left: -15px !important;
	  }
	.footer_bgcolor.shubhcards {
		background-color: #cc0001;
		margin-top: 0% !important;
	}
	.footer-col.Shubhcards img{
		width: 50% !important;
        margin-left: 0rem;
        margin-top: -2rem;
	}
	.footer-content-Shubhcards {
		width: 95% !important;
		text-align: justify;
		font-size: 14px;
		margin-top: 1rem;
		margin-left: 0rem;
	}
	footer .footer-links .footer-col{
		margin-left:0.5rem !important;
	};
    .poweredbylogo {
        position: relative;
        height: 70;
        top: 100px;
        right: -68px !important;
        min-width: 22%;
        min-height: 20%;
        width: 16%;
        margin-top: -4% !important;
        margin-right: 0%;
    }
	.pwrdby {
        position: relative;
        top: 78px !important;
        white-space: nowrap;
        right: 95px !important;
        width: 16%;
        margin-top: -13%;
        margin-right: 0%;
    }
	.copyright {
		margin-top: -1.2rem;
		color: #f5f5f5;
		margin-left: 2.3rem;
		margin-bottom: -11% !important;
	}
	 .i-c {
		color: #fff;
		margin-left: 7px!important;
		  font-size: 15px !important;
		// margin-top: 2px;
	}
	// .owl-next {
	// 	position: absolute;
	// 	top: 70%;
	// 	right: 45% !important;
	// 	transform: translateY(-50%);
	// 	border: 2px solid rgb(255, 255, 255);
	// 	border-radius: 50%;
	// 	background: none;
	// 	z-index: 2;
	// 	padding: 6px 12px 4px 14px !important;
	// }
	.owl-next-video  {
		color: #fff;
		font-size: 25px !important;
		margin-top: 2px;
	}
	.owl-next-video {
		position: absolute;
		top: 33%;
		right: 44% !important;
		transform: translateY(-50%);
		border: 2px solid rgb(255, 255, 255);
		border-radius: 50%;
		background: none;
		z-index: 2;
		padding: 4px 12px 1px 15px !important;
	}
	.owl-next-button {
		position: absolute;
		top: 57% !important;
		right: 66%;
		transform: translateY(-50%);
		border: 2px solid rgb(255, 168, 28) !important;
		border-radius: 50%;
		background: none;
		z-index: 2;
		padding: 0px !important;
		width: 22px;
        height: 22px;
	}
	.owl-next-button  {
		position: relative;
		top: -3.5px;
		color: #ffa81c;
		font-size: 14px !important;
		margin-left: 9px !important;
	}
	.owl-next-button.elite {
		position: absolute;
		top: 56% !important;
		right: 67% !important;
		transform: translateY(-50%);
		
		border-radius: 50%;
		background: none;
		z-index: 2;
		
	}
	.owl-next-button.luxury {
		position: absolute;
		top: 57% !important;
		right: 64% !important;
		transform: translateY(-50%);
		
		border-radius: 50%;
		background: none;
		z-index: 2;
		
	}
	.page-home {
		margin-bottom: -36% !important;
	}
	.video-controls {
		position: absolute;
		top: 24% !important;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
	}
	header .main-navbar .nav-menu>li .nav-submenu li a {
		border: 0 !important;
		background: transparent;
		color: #222;
		border-radius: 0 !important;
		font-size: 14px;
		font-weight: 400;
		text-transform: capitalize;
		padding: 5px 35px;
		display: block;
		line-height: 1.9;
		margin-left: 5% !important;
		margin-top: 6px;
	}
	

  }
  @media (min-width: 768px) {
    .footer_bgcolor.shubhcards {
        background-color: rgba(204, 0, 1, 1);
        margin-top: 2% !important; 
    }
	.overlay-image.video-image{
		position: absolute;
		top: 0;
		left: 0;
		width: 40% !important;
		height: auto;
		object-fit: cover;
		margin-left: 29rem;
		margin-top: 20%;
	}
	.page-home{
		margin-bottom: -10% !important;
	}
}
.color-container{
	background-color: #eeebda;
}
.ngb-tp{
	justify-content: center;
}